<template>
  <div class="Classify_I">
    <div class="headNavs">
      <div class="headTilte">商品分类</div>
      <div class="headDetails">说明：商品管理</div>
    </div>
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="Refresh()"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
      </div>
      <el-table
          :data="tableData"
          row-key="id"
          class="table ddddd"
          :indent="20"
          style="width: 100%"
          ref="multipleTable"
          @row-click="handleRowClick"
          header-cell-class-name="table-header"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          @selection-change="select_change">
        <el-table-column prop="name" label="分类名称" align="left"></el-table-column>
        <el-table-column prop="is_show" label="显示状态"  align="center">
          <template  #default="scope">
              <span>{{ {0:'隐藏',1:'显示'}[scope.row.is_show||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tuijian" label="推荐状态"  align="center">
          <template  #default="scope">
              <span>{{ {10:'推荐',20:'不推荐'}[scope.row.tuijian||10] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="weigh" label="权重"  align="center"> </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>



    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>上级分类：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row_piii" placeholder="请选择是否启用"  >
                <el-option v-for="item in DData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>分类名称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请填写名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>简介</div>
            <div class="EditNewFormItemIpt">
              <el-input  type="textarea" v-model="Row.intro" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="Holiday">
              <div>显示状态</div>
              <div>
                <el-radio v-model="Row.is_show" :label="1" size="large">显示</el-radio>
                <el-radio v-model="Row.is_show" :label="0" size="large">隐藏</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="Holiday">
              <div>推荐状态</div>
              <div>
                <el-radio v-model="Row.tuijian" :label="10" size="large">一般</el-radio>
                <el-radio v-model="Row.tuijian" :label="20" size="large">推荐</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'  
export default {
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      CateList:[],
      DData:{},//分类列表
      Row_piii:[], //分类名称
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      // 列表数据
      Row: {
        pid: "",
        name: "",
        intro: "",
        is_show: '',
        tuijian: '',
        weigh: "",
      },
      fileList:[],
      addPanel: false,
      editPanel: false,
      editId: 0,
      titleMath:'',
      total: 0,
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/goods/category/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.rows.length
        that.$forceUpdate()
      })
    },
     // 刷新
    Refresh(){
      var that = this
      that.loadTable();
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        pid: "",
        name: "",
        intro: "",
        is_show: 1,
        tuijian: 10,
        weigh: "",
      };
      that.fileList = [];
      that.cateList()
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/goods/category/add',
          data: {
           row:{
              pid : that.Row_piii,
              name: that.Row.name ,
              intro: that.Row.intro ,
              is_show: that.Row.is_show ,
              tuijian: that.Row.tuijian ,
              weigh: that.Row.weigh ,
           }
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
            that.Row_piii = ''
          }
        })
      }
    },
    cateList(){
      var that = this
      axios({ method: 'post', url: '/admin/ajax/cateList',   //商品分类
      }).then(function (res) {
          if (res.code == 401) {
            that.$router.push('/Login');
          }else {
            that.CateList = res.data.data
          }
      }) 
      axios({ method: 'post', url: '/admin/goods/category/index',   //商品分类
      }).then(function (res) {
          if (res.code == 401) {
            that.$router.push('/Login');
          }else {
            // var arr = 
            that.DData = res.data.data.rows
            that.DData.unshift({id : 0 , name : '顶级分类'})
          }
      }) 
    },
    // 编辑保存
    BtnEdit() {
      var that = this
      console.log(that.editId);
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/goods/category/edit',
          data: {
            id: that.editId,
            row:{
              pid: that.Row_piii,
              name: that.Row.name,
              intro: that.Row.intro,
              is_show: that.Row.is_show,
              tuijian: that.Row.tuijian,
              weigh: that.Row.weigh,
            }
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
            that.Row_piii = ''
          }
        })
      }
    },
    //获取编辑内容
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.id
      that.cateList()
      axios.get('/admin/goods/category/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = {
            pid: res.data.data.row.id,
            name: res.data.data.row.name,
            intro: res.data.data.row.intro,
            is_show: parseInt(res.data.data.row.is_show),
            tuijian: parseInt(res.data.data.row.tuijian),
            weigh: res.data.data.row.weigh,
          }
          that.Row_piii = res.data.data.row.pid
          //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_image;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
    },

    // 取消
    Close() {
      var that = this
      that.addPanel = false;
      that.editPanel = false;
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    },
    delTable(arr) {
      var that = this   
      axios({
        method: 'DELETE',
        url: '/admin/goods/category/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.image = e.data.url
    },
    handleChange(value) {
      console.log(value);
      var that = this 
      var num = Number(that.Row_piii.slice(-1))
      console.log(num)
      that.Row.pid = num
    },
    handleExceed  (files)  {
      this.$refs["upload-image"].clearFiles()
      this.$refs["upload-image"].handleStart(files[0])
      this.$refs["upload-image"].submit();
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url);
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
      
    },
  }
}
</script>
<style scoped>
.Holiday{
  display: flex;
}
.Holiday div:nth-child(1){
  margin-right: 30px;
}
</style>